import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/register/RegisterView.vue')
  },
  {
    path: '/library',
    name: 'library',
    component: () => import('../views/library/LibraryView.vue'),
    redirect: '/library/main',
    children:[
      {
        path: '/library/main',
        name: 'main',
        component: () => import('../views/library/container/MainView.vue')
      },
      {
        path: '/library/allBook',
        name: 'allBook',
        component: () => import('../views/library/container/AllBookView.vue')
      },
      {
        path: '/library/findBook',
        name: 'findBook',
        component: () => import('../views/library/container/FindBookView.vue')
      },
      {
        path: '/library/myHome',
        name: 'myHome',
        component: () => import('../views/library/container/MyHomeView.vue')
      },
      {
        path: '/library/myBook',
        name: 'myBook',
        component: () => import('../views/library/container/MyBookView.vue')
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
